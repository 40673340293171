// 子页面通信父页面
function newWindowOpener() {
  // 是子页面
  var parentWindow = window.opener;
  if (parentWindow) {
    var query = queryParse();
    if (query.code && /^wechat_login/.test(query.state)) {
      var parentSearch = parentWindow.location.search;
      var nextQuery = _extend({}, queryParse(parentSearch), query);
      var nextSearch = querySearch(nextQuery);
      parentWindow.location.href = parentWindow.location.pathname + '?' + nextSearch;
      window.close();
    }
  }
}
function _extend(target, source) {
  var getOwnPropertySymbols = Object.getOwnPropertySymbols;
  var hasOwnProperty = Object.prototype.hasOwnProperty;
  var propIsEnumerable = Object.prototype.propertyIsEnumerable;
  var from;
  var to = target;
  var symbols;
  for (var s = 1; s < arguments.length; s++) {
    from = Object(arguments[s]);
    for (var key in from) {
      if (hasOwnProperty.call(from, key)) {
        to[key] = from[key];
      }
    }
    if (getOwnPropertySymbols) {
      symbols = getOwnPropertySymbols(from);
      for (var i = 0; i < symbols.length; i++) {
        if (propIsEnumerable.call(from, symbols[i])) {
          to[symbols[i]] = from[symbols[i]];
        }
      }
    }
  }
  return to;
}
function querySearch(obj) {
  var arr = [];
  for (var k in obj) {
    arr.push(k + '=' + obj[k]);
  }
  return arr.join('&');
}
function queryParse(str) {
  var search = str || location.search;
  var query = {};
  if (search) {
    var queryArray = search.slice(1).split('&');
    queryArray.forEach(function (item) {
      var kv = item.split('=');
      query[kv[0]] = kv[1];
    });
  }
  return query;
}

function newWindowOpenr2() {
  var e = window.opener;
  if (e) {
    var b = queryParse();
    if (b.code && /^wechat_login/.test(b.state)) {
      var c = e.location.search;
      var a = _extend({}, queryParse(c), b);
      var d = querySearch(a);
      e.location.href = e.location.pathname + '?' + d;
      window.close();
    }
  }
}
function _extend(e, a) {
  var k = Object.getOwnPropertySymbols;
  var c = Object.prototype.hasOwnProperty;
  var f = Object.prototype.propertyIsEnumerable;
  var g;
  var h = e;
  var b;
  for (var l = 1; l < arguments.length; l++) {
    g = Object(arguments[l]);
    for (var j in g) {
      if (c.call(g, j)) {
        h[j] = g[j];
      }
    }
    if (k) {
      b = k(g);
      for (var d = 0; d < b.length; d++) {
        if (f.call(g, b[d])) {
          h[b[d]] = g[b[d]];
        }
      }
    }
  }
  return h;
}
function querySearch(c) {
  var a = [];
  for (var b in c) {
    a.push(b + '=' + c[b]);
  }
  return a.join('&');
}
function queryParse(d) {
  var a = d || location.search;
  var c = {};
  if (a) {
    var b = a.slice(1).split('&');
    b.forEach(function (e) {
      var f = e.split('=');
      c[f[0]] = f[1];
    });
  }
  return c;
}
if (process.env.APP_ENV === 'development') {
  newWindowOpener();
} else {
  newWindowOpenr2();
}
